import React from "react";
import ApplicationBar from "../components/ApplicationBar";

export default function Layout({ pageTitle, children }) {
  const yearNow = new Date().getFullYear();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ApplicationBar />
      {pageTitle && (
        <header
          style={{
            textAlign: "center",
            background: "rgba(255, 255, 255, 0.7)",
            paddingBottom: "1.5em",
            paddingTop: "1.5em",
          }}
        >
          <h2 style={{ fontWeight: 400, textTransform: "uppercase" }}>
            {pageTitle}
          </h2>
        </header>
      )}
      <div className="pk-main-content">{children}</div>
      <footer
        style={{
          padding: "5px",
          textAlign: "center",
          fontSize: "0.9em",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <p>©&nbsp;Pilvikone&nbsp;{yearNow}</p>
      </footer>
    </div>
  );
}
