import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgba(50, 50, 50, 1)',
    position: 'sticky',
    top: 0,
  },
  logo: (props) => ({
    color: '#fff',
    borderTop: '4px solid #fff',
    fontSize: props.smScreen ? '48px' : '36px',
    fontWeight: '300',
    letterSpacing: '.5px',
    lineHeight: '44px',
    padding: props.smScreen ? '40px 0 22px 0' : '20px 0 10px 0',
  }),
  link: {
    textShadow: `none`,
    backgroundImage: `none`,
    textDecoration: 'none',
  },
  listLink: {
    display: 'inline-block',
    marginRight: '1.5em',
    textTransform: 'uppercase',
    '& a': {
      color: '#fff',
    },
  },
}));

function ListLink(props) {
  const classes = useStyles();

  return (
    <li className={classes.listLink}>
      <Link
        activeStyle={{ borderBottom: '2px solid #fff' }}
        className={classes.link}
        to={props.to}
      >
        {props.children}
      </Link>
    </li>
  );
}

export default function ApplicationBar() {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const classes = useStyles({ smScreen });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Link to="/" className={classes.link}>
          <h1 className={classes.logo}>PILVIKONE</h1>
        </Link>
        <span style={{ flex: '1 1 auto' }}></span>
        {smScreen && (
          <nav style={{ alignSelf: 'flex-end' }}>
            <ul>
              <ListLink to="/">Musiikki</ListLink>
              <ListLink to="/story">Tarina</ListLink>
            </ul>
          </nav>
        )}

        {!smScreen && (
          <>
            {' '}
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ color: '#fff' }}
            >
              <MenuIcon style={{ fontSize: 30 }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to="/">
                Musiikki
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/story">
                Tarina
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
